/* You can add global styles to this file, and also import other style files */
@import "primeng/resources/primeng.min.css";
@import "primeflex/primeflex.css";
@import "primeicons/primeicons.css";

html {
    font-size: 1rem;
    height: 100%;
}

body {
    font-family: var(--font-family);
    background-color: var(--surface-ground);
    color: var(--text-color);
    padding: 0;
    margin: 0;
    min-height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.errorInput {
    color: red;
    margin-top: 7px;
    display: flex;
    justify-content: center;
    position: relative;
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(./assets/fonts/Roboto.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

.proActivo {
    background: #18AD00;
    padding: 5px;
    border-radius: 5px;
    color: white;
    width: 5vw;
    display: inline-block;
    text-align: center;
}

.entregada {
    background: #adffa0;
    border-radius: 5px;

    &:hover {
        cursor: pointer;
    }
}

.cierre_parcial  {
    background: #e7e6e6;
    border-radius: 5px;

    &:hover {
        cursor: pointer;
    }
}

.cierre_total  {
    background: #fab431;
    border-radius: 5px;

    &:hover {
        cursor: pointer;
    }
}

.navegacionUsuario {

    &:hover {
        cursor: pointer;
    }
}

.abierta  {
    background: #fff877;
    border-radius: 5px;

    &:hover {
        cursor: pointer;
    }
}

.entregada_coste {
    background: #adffa0;
    border-radius: 5px;
}

.cierre_parcial_coste  {
    background: #e7e6e6;
    border-radius: 5px;
}

.cierre_total_coste  {
    background: #fab431;
    border-radius: 5px;
}

.abierta_coste  {
    background: #fff877;
    border-radius: 5px;
}

.proBorrador {
    background: #3B82F6;
    padding: 5px;
    border-radius: 5px;
    color: white;
    width: 5vw;
    display: inline-block;
    text-align: center;
}
.proCerrado {
    background: #EF4444;
    padding: 5px;
    border-radius: 5px;
    color: white;
    width: 5vw;
    display: inline-block;
    text-align: center;
}
.gestAbierta {
    background: #fff877;
    border-radius: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
}
.gestEntregada {
    background: #adffa0;
    border-radius: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
}
.gestCierreParcial {
    background: #c8c8c8;
    border-radius: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
}
.dangerDialog {
    background-color: #EF4444 !important;
}

.warningDialog {
    background-color: #37c5db !important;
}

.iconProGestion {
    background-image: url(assets/icons/pro_gestion.png);
    width: 26px;
    height: 20px;
    margin-right: 0.7rem;
    filter: brightness(0) saturate(100%) invert(14%) sepia(68%) saturate(4675%) hue-rotate(203deg) brightness(90%);

}

.iconProDuplicar {
    background-image: url(assets/icons/pro_duplicar.png);
    width: 17px;
    height: 21px;
    margin-right: 0.7rem;
}

.iconProConfig {
    background-image: url(assets/icons/pro_config.png);
    width: 30px;
    height: 20px;
    margin-right: 0.4rem;
}

.usuarioActivo {
    color: #525252;
    margin-left: -5px;
}

.botonNuevo {
    position: absolute;
    top: 71px;
    right: 31px;
}

.botonTablaCalendario {
    position: absolute;
    top: 84px;
    left: 505px;
    color: #1651ab;
}

.iconHorasImputadas {
    background-image: url(assets/icons/horas_imputadas.png);
    width: 28px;
    height: 24px;
    margin-right: 0.5rem;
    background-size: contain; /* Ajusta el tamaño de la imagen para que se ajuste completamente dentro del contenedor */
    background-repeat: no-repeat;
}

.iconHorasRestantes {
    background-image: url(assets/icons/horas_restantes.png);
    width: 28px;
    height: 24px;
    margin-right: 0.5rem;
    background-size: contain; /* Ajusta el tamaño de la imagen para que se ajuste completamente dentro del contenedor */
    background-repeat: no-repeat;
}

.iconLinea {
    background-image: url(assets/icons/iconLinea.png);
    width: 20px;
    height: 20px;
    background-size: contain; /* Ajusta el tamaño de la imagen para que se ajuste completamente dentro del contenedor */
    background-repeat: no-repeat;
}

.iconPartida {
    background-image: url(assets/icons/iconPartida.png);
    width: 20px;
    height: 20px;
    background-size: contain; /* Ajusta el tamaño de la imagen para que se ajuste completamente dentro del contenedor */
    background-repeat: no-repeat;
}

.minimo {
    width: 20px;
}

.finde {
    background-color: #e7e6e6 !important;
}

.festivo {
    background-color: #fbd293 !important;
}

.deshabilitado {
    background-color: #e7e6e6;
    opacity: 25%;
}
p-celleditor .deshabilitado  {
    opacity: 40%;
    background: bottom;
}

.totales {
    background-color: aliceblue;
}

:host ::ng-deep .p-dialog {
    max-height: none !important;   
}

.elipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.bloque {
    background-color: #F8FAFC;
    
    box-shadow: 0px 3px 4px -2px rgba(0, 0, 0, 0.15);
}

.bloqueMenu {
    background-color: #F8FAFC;
    
}

.pi-file-ppt {
    background-image: url(assets/icons/file-powerpoint-regular.svg);
    width: 12px;
    height: 16px;
    margin-left: 2px;    
}

.pi-file-ppt.text-3xl {
    width: 1.4rem !important;
    height: 1.9rem !important;
}

.pi-file-excel {
    color: #217346 !important;
}

.pi-file-pdf {
    color: #FF0000 !important;
}

.pi-file-word {
    color: #2B579A !important;
}

.pi-envelope {
    color: #0072C6 !important;
}

.pi-image {
    color: #34A853 !important;
}

.destacado {
    color: #5590F0;
}

.excel {
    font-size: 1.5rem;
    color: green;
    border: 1px solid;
    padding: 5px;
    border-radius: 5px;
    border-color: #ced4da;
}